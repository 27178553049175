import { NPRequestStatus, ProjectStatusEnum } from '@gtintel/platform-types';
export const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);
  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};
const newShade = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = (decimalColor >> 8 & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | b << 8 | r << 16).toString(16)}`;
  } else {
    return hexColor;
  }
};
export const COLOURS = {
  black: '#000000',
  white: '#ffffff',
  gray: '#778ca2',
  lightGray: newShade('#778ca2', 100),
  darkGray: newShade('#778ca2', -50),
  gold: '#d89d00',
  lightGold: newShade('#d89d00', 100),
  darkGold: newShade('#d89d00', -50),
  teal: '#2babb9',
  lightTeal: newShade('#2babb9', 100),
  darkTeal: newShade('#2babb9', -50),
  green: '#59ad27',
  lightGreen: newShade('#59ad27', 100),
  darkGreen: newShade('#59ad27', -50),
  red: '#e3170a',
  lightRed: newShade('#E3170A', 100),
  darkRed: newShade('#E3170A', -50),
  pink: '#fe4d97',
  lightPink: newShade('#fe4d97', 100),
  darkPink: newShade('#fe4d97', -50),
  blue: '#4d7cfe',
  lightBlue: newShade('#4d7cfe', 100),
  darkBlue: newShade('#4d7cfe', -50)
};
export const ProjectStatusColourMap = {
  [ProjectStatusEnum.PENDING]: COLOURS.gold,
  [ProjectStatusEnum.MATCHED]: COLOURS.pink,
  [ProjectStatusEnum.ACTIVATED]: COLOURS.green,
  [ProjectStatusEnum.COMPLETED]: COLOURS.blue,
  [ProjectStatusEnum.CLOSED]: COLOURS.gray
};
export const ProjectStatusBackgroundColourMap = {
  [ProjectStatusEnum.PENDING]: COLOURS.lightGold,
  [ProjectStatusEnum.MATCHED]: COLOURS.lightPink,
  [ProjectStatusEnum.ACTIVATED]: COLOURS.lightGreen,
  [ProjectStatusEnum.COMPLETED]: COLOURS.lightBlue,
  [ProjectStatusEnum.CLOSED]: COLOURS.lightGray
};
export const NPRequestStatusColourMap = {
  [NPRequestStatus.Draft]: COLOURS.gold,
  [NPRequestStatus.Sent]: COLOURS.pink,
  [NPRequestStatus.Declined]: COLOURS.red,
  [NPRequestStatus.Accepted]: COLOURS.teal,
  [NPRequestStatus.Cancelled]: COLOURS.red,
  [NPRequestStatus.Assigned]: COLOURS.green,
  [NPRequestStatus.AcceptedCancelled]: COLOURS.red,
  [NPRequestStatus.Finished]: COLOURS.gray
};
export const NPRequestStatusBackgroundColourMap = {
  [NPRequestStatus.Draft]: COLOURS.lightGray,
  [NPRequestStatus.Sent]: COLOURS.lightPink,
  [NPRequestStatus.Declined]: COLOURS.lightRed,
  [NPRequestStatus.Accepted]: COLOURS.lightTeal,
  [NPRequestStatus.Cancelled]: COLOURS.lightRed,
  [NPRequestStatus.Assigned]: COLOURS.lightGreen,
  [NPRequestStatus.AcceptedCancelled]: COLOURS.lightRed,
  [NPRequestStatus.Finished]: COLOURS.lightGray
};