import { api } from '..';
import parseResponse from './parseResponse';
export const getListProjectAction = data => api.project.list(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getListProjectActionAdmin = data => api.project.list_source(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getProjectOptions = params => api.project.options(params).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getProjectType = () => api.project.type().then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getProjectDetail = id => api.project.getProjectDetail(id).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getProjectPartner = id => api.project.getProjectPartner(id).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getProjectListForChat = data => api.project.getProjectListForChat(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getProjectDetailNew = data => api.project.getProjectDetailNew(data).then(res => Promise.resolve(res.data)).catch(e => Promise.reject(e.res));
export const getProjectActivity = (id, data) => api.project.project_activity(id, data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getProjectReports = projectId => api.project.project_reports(projectId).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const createProject = (idType, data) => api.project.createProject(idType, data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const updateProject = (id, idType, data) => api.project.updateProject(id, idType, data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const deleteProject = id => api.project.deleteProject(id).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const createReport = data => api.project.createReport(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const updateProjectDescription = (id, data) => api.project.updateProjectDescription(id, data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const updateReport = (data, id) => api.project.updateReport(data, id).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getReport = id => api.project.getReport(id).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const notifyReportDownload = id => api.project.reportDownloadNotification(id).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const uploadFile = value => api.project.uploadFile(value).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const changeStatusAction = (id, data) => api.project.changeStatus(id, data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getPartnerProposal = (id, search = '') => api.project.getPartnerProposal(id, search).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getProjectsProposal = id => api.project.getProjectsProposal(id).then(response => Promise.resolve(response.data.results)).catch(e => Promise.reject(e.response));
export const projectsproposal = data => api.project.projectsProposal(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const acceptProposal = data => api.project.proposalAction(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const declineProposal = data => api.project.proposalAction(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const switchProjectStatus = (data, da) => api.project.switchProjectStatus(data, da).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const projectReportsAccept = data => api.project.projectReportsAccept(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const adminUnmatch = data => api.project.adminUnmatch(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const clientDeclined = data => api.project.clientDeclined(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const deleteMemberFromProject = ({
  projectId,
  memberId
}) => api.project.deleteMemberFromProject(projectId, memberId).then(response => Promise.resolve(response.data));
export const addMemberToProject = ({
  data,
  projectId
}) => api.project.addMemberToProject(data, projectId).then(response => Promise.resolve(response.data));
export const getPartnerStatus = ({
  projectId
}) => api.project.getPartnerStatus(projectId).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getProjectDescription = ({
  data,
  projectId
}) => api.project.getProjectDescription(data, projectId).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const SwitchMuteState = ({
  projectId,
  is_muted
}) => {
  api.project.switchMuteStateProject(projectId, is_muted).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
};
export const uploadDocument = data => api.project.uploadDocument(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getUploadList = ({
  projectId,
  path
}) => api.project.listDocuments(projectId, path).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const removeDocument = ({
  id
}) => api.project.removeDocument(id).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const removeFolder = data => api.project.removeFolder(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const moveFolder = data => api.project.moveFolder(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const createProjectFlags = (id, data) => api.flags.create(id, data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getProjectFlags = projectId => api.flags.get(projectId).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getSubject = id => parseResponse(api.project.getSubject(id));
export const deleteSubject = id => parseResponse(api.project.deleteSubject(id));
export const updateSubject = (id, data) => parseResponse(api.project.updateSubject(id, data));
export const createProjectSubject = (projectId, data) => parseResponse(api.project.createProjectSubject(projectId, data));
export const listProjectSubjects = projectId => parseResponse(api.project.listProjectSubjects(projectId));
export const createSubProject = projectId => parseResponse(api.project.createSubProject(projectId));
export const updateProjectDetailV5 = (id, data) => {
  return api.project.updateProjectV5(id, data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
};
export const getPresignedUrl = data => {
  return parseResponse(api.attachment.getPresignedUrl(data));
};
export const addDocumentToProject = (projectId, data) => {
  return api.project.addDocumentToProject(projectId, data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
};
export const listNPRequests = () => parseResponse(api.project.listNPRequests());
export const editNPRequest = (id, data) => parseResponse(api.project.editNPRequest(id, data));
export const copyNPRequest = id => parseResponse(api.project.copyNPRequest(id));
export const acceptNPRequest = id => parseResponse(api.project.acceptNPRequest(id));
export const declineNPRequest = id => parseResponse(api.project.declineNPRequest(id));
export const cancelNPRequest = id => parseResponse(api.project.cancelNPRequest(id));
export const sendNPRequest = id => parseResponse(api.project.sendNPRequest(id));
export const assignNPRequest = id => parseResponse(api.project.assignNPRequest(id));
export const deleteNPRequest = id => parseResponse(api.project.deleteNPRequest(id));
export const listProjectDocuments = id => parseResponse(api.project.listDocumentsV2(id));
export const projectAssignments = () => parseResponse(api.project.projectAssignments());
export const updateFragment = (id, unsafe_body) => parseResponse(api.project.updateFragment(id, unsafe_body));